<!-- 组件内基本样式 -->
<template>
  <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" v-on="$listeners" />
  <el-popover :content="title" :placement="position" trigger="hover" popper-class="my-popper"
    v-else-if="!isExternal && title.length > 0" >
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners" slot="reference">
      <use :xlink:href="iconName" />
    </svg>
  </el-popover>
  <svg :class="svgClass" aria-hidden="true" v-on="$listeners" v-else-if="!isExternal && title.length === 0">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
/* doc: https://panjiachen.github.io/vue-element-admin-site/feature/component
/svg-icon.html#usage */
import { isExternal } from '@/libs/validate'

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass)
    },
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      }
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>


<style>
.my-popper {  
  padding: 6px 12px;
  width: max-content;
  min-width: 0;
  height: 28px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2000);
  border-radius: 4px;
  border: none;
  color: #202020;
  font-size: 12px;
}
</style>