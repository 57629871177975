<template>
  <div class="my-loading" :style="{background:bgcColor}" v-if="loading">
    <div class="loading-box">
      <img src="../../assets/images/myloaidngimg@2x.png" alt="" class="loading-img">
      <div class="loading-text">{{text}}</div>
      <el-progress :percentage="percentage" color="#FFB700" :show-text="false" :stroke-width="12"></el-progress>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage:0,
      timer:null,
      loading:false,
      text:'加载中...',
      bgcColor: '#F7F7F7"'
    }
  },
  methods:{
    getDataProgress() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.percentage == 90) {
          clearInterval(this.timer)
          return
        }
        if (this.percentage < 60) {
          this.percentage += 4
        } else {
          this.percentage += 2
        } 
      }, 20)
    },
  },
}
</script>

<style lang="scss" scoped>
  .my-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
    .loading-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 264px;
      text-align: center;
       .loading-img {
         display: block;
         margin: 0 auto;
         width: 96px;
         height: 96px;
      }
      .loading-text {
        font-size: 16px;
        color: #202020;
        margin-top: 17px;
        margin-bottom: 16px;
      }
    }
   
  }
  ::v-deep .el-progress-bar__outer {
    background: rgba(255, 183, 0, .3)}

</style>