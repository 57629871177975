import Vue from 'vue'
import Vuex from 'vuex'
import request from '../libs/api.request'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    userConfig: {},
    allChapterList: [],
    enableChapterList: [],
    disableChapterList: [],
    modelList: [],
    showShortcutkey: true,
    showCatelog: true, //显示隐藏目录
    toggleCatelog: true, // 切换目录或者列表
    totalChapterData: {}, // 卡牌盒数据汇总 
    isSelectAll: false, //开启全选
    isFullScreen: false,
    selectedModel: {}, // 用户的自己选择的模版 // 大的编辑器
    selectedModel1: {}, // 用户的自己选择的模版 // 底部编辑器
    showIntroduceVip: false, // 前往开通会员的弹窗
  },
  mutations: {
    changeShowIntroduceVip(state, payload) {
      state.showIntroduceVip = payload
    },
    setSelectedModel1(state, model) {
      state.selectedModel1 = model
    },
    setSelectedModel(state, model) {
      state.selectedModel = model
    },
    deleteChapter(state, payload) {
      state.allChapterList.splice(payload, 1)
    },
    setUserConfig(state, payload) {
      state.userConfig = payload
    },
    setToggleFullScreen(state, payload) {
      state.isFullScreen = payload
    },
    setSelectAll(state, payload) {
      state.isSelectAll = payload
    },
    setAllChapterList(state, payload) {
      state.allChapterList = payload
    },
    setChangetToggleCatelog(state, payload) {
      state.toggleCatelog = payload
    },
    setShowCatelog(state, payload) {
      state.showCatelog = payload
    },
    setModelList(state, payload) {
      state.modelList = payload
    },
    setEnableChapterList(state, payload) {
      state.enableChapterList = payload
    },
    setDisableChapterList(state, payload) {
      state.disableChapterList = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
    changeShowShortcutkey(state, payload) {
      state.showShortcutkey = payload
    },
    setTotalChapterData(state, payload) {
      state.totalChapterData = payload
    },
    setRenameChapter(state, chapter) {
      state.allChapterList.forEach(v => {
        if (v.chapterId === chapter.chapterId) {
          v.name = chapter.name
        }
      })
    },
    changeModelList(state,modelId) {
      for(let i=0;i<state.modelList.length;i++){
        if(state.modelList[i].modelId === modelId){
          state.modelList.splice(i,1)
          break
        }
      }
    },
    // changeStatus 增加还是减少，num 数量
    // changeTreeShowNum(state, chapterId, changeStatus, num ){
    //   console.log(state.enableChapterList);
    //   // function 
    //   // for(let i=0;i<state.enableChapterList.length;i++){}
    // }
  },
  actions: {
    async modifyUserInfo({ commit }) {
      const res = await request.request({
        url: '/user/info'
      })
      commit('setUserInfo', res)
    },
    async modifyChapterList({ commit }) {
      const res = await request.request({
        url: '/study/home'
      })
      const disableChapterList = {}
      disableChapterList.childList = res.disableChapterList
      disableChapterList.isShowMenu = false
      disableChapterList.name = '已停学'
      disableChapterList.chapterId = 999
      disableChapterList.newCardNum = [0, 0]
      disableChapterList.revCardNum = [0, 0]
      disableChapterList.cardNum = 0
      res.enableChapterList.unshift({ chapterId: 1001, childList: [], isShowMenu: false, name: '全部卡片', newCardNum: [0, 0], revCardNum: [0, 0], cardNum: 0 })
      commit('setEnableChapterList', res.enableChapterList)
      commit('setDisableChapterList', [disableChapterList])
    },
    async modifyTotalChapterData({ commit }) {
      const res = await request.request({
        url: '/study/overview'
      })
      commit('setTotalChapterData', res)
    },
    async modifyAllChapterList({ commit }) {
      const res = await request.request({
        url: "/chapter/all",
      })
      commit('setAllChapterList', res.list)
    },
    async getModelList({ commit }) {
      const res = await request.request({
        url: "/model/all",
      })
      commit('setModelList', res.list)
    },
    // changeTreeShowNum({ state , commit }, upDataInfo) {
    //   console.log(state.enableChapterList);
    //   // function 
    //   for(let i = 0; i < state.enableChapterList.length; i++){
        
    //   }
    // }
  },
  getters: {
    isVip(state) {
      if (state.userInfo.vip) {
        return state.userInfo.vip.available
      }
    },
    vipLimitData(state) {
      return state.userInfo.freeLimit
    },
    getModelList(state) {
      return state.modelList
    },
    getTotalDatas(state) {
      return state.totalChapterData
    }
  },
  plugins: [createPersistedstate({
    storage: window.sessionStorage
  })]
})
