import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui'
import './assets/editor-theme.css'
import './assets/css/index.scss'
import './assets/theme/index.css'
import 'animate.css';
import myLoading from './libs/myLoading/index'
import echarts from './myEcharts'
import '@/assets/icons'
Vue.use(myLoading)
Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
