import MyLoading from './MyLoading.vue'
let instance = null
export default {
  install(Vue) {
    if(!instance) {
      let myLoading = Vue.extend(MyLoading)
      instance = new myLoading({
        //创建一个div,并挂载上去
        el: document.createElement("div"),
      })
      document.body.appendChild(instance.$el);
      instance.percentage = 0
      instance.loading = false; //默认false,关闭loading
      let customMethods = {
        start(option = {}) {
          instance.getDataProgress()
          instance.bgcColor = '#F7F7F7'
          instance.text = '加载中...'
          instance.percentage = 0
          instance.loading = true;
          if(option.bgcColor) {
            instance.bgcColor = option.bgcColor
          }
          if(option.text) {
            instance.text = option.text
          }
        },
        finish() {
          clearInterval(instance.timer)
          setTimeout(() => {
            instance.loading = false;  
          }, 300);
        },
        finishProgress() {
          instance.percentage = 100
        }
      };
      if (!Vue.$myLoading) {
        Vue.$myLoading = customMethods;
        //挂载到原型上
        Vue.prototype.$myLoading = Vue.$myLoading;
      } else {
        console.log("$loading方法已被占用");
      }
    }
  }
}