import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;

// 解决重复 跳转同一个路由
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '',
  //   redirect: '/',
  // },
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    meta:{
      needToken:false
    }
  },
  {
    path: '/home',
    redirect: '/home/studyPlatform/cardAll',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: 'studyPlatform',
        component: () => import('@/views/newStudyPlatfrom.vue'),
        meta:{
          needToken:true
        },
        children: [
          {
            path: 'chapter/:chapterId',
            name: 'Chapter',
            component: () => import('@/views/MyChapter.vue'),
            meta:{
              needToken:true
            },
          },
          {
            path: 'cardAll',
            component: () => import('@/views/CardAll.vue'),
            name: 'CardAll',
            meta:{
              needToken:true
            },
          },
        ]
      },
      {
        path: 'summary',
        name: 'Summary',
        component: () => import('@/views/Summary.vue'),
        meta:{
          needToken:true
        },
      },
      {
        path: 'organization',
        name: 'Organization',
        component: () => import('@/views/Organization.vue'),
        meta:{
          needToken:true
        },
      },
      {
        path: 'shop',
        name: 'Shop',
        component: () => import('@/views/Shop.vue'),
        meta:{
          needToken:true
        },
      },
      {
        path: 'userCenter',
        name: 'UserCenter',
        component: () => import('@/views/UserCenter.vue'),
        meta:{
          needToken:true
        },
        children: [
          {
            path: 'vip',
            name: 'VIP',
            component: () => import('@/views/Vip.vue'),
            meta:{
              needToken:true
            },
          }
        ]

      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta:{
      needToken:false
    },
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('@/views/Info.vue'),
    meta:{
      needToken:false
    },
  },
  {
    path: '/h5',
    name: 'H5',
    component: () => import('@/views/H5.vue'),
    meta:{
      needToken:false
    },
  },
  {
    path: '/infoH5',
    name: 'InfoH5',
    component: () => import('@/views/InfoH5.vue'),
    meta:{
      needToken:false
    },
  },
  {
    path: '/preDownload',
    name: 'preDownload',
    component: () => import('@/views/preDownload.vue'),
    meta:{
      needToken:false
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?7d74fda9ca6c7f5d8cecb0b95a13bbce";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  // console.log("to.path = " + to.path);
  if(to.meta.needToken) {
    if (!VueCookies.get('authToken')) {
      router.push('/login')
      return;
    }
  }
  // if (to.path !== '' && to.path !== '/' && to.path !== '/info' && to.path !== '/info/' && to.path !== '/h5' && to.path !== '/preDownload' && to.path !== '/infoH5') {
  //   if (to.path !== '/login') {
  //     if (!VueCookies.get('authToken')) {
  //       router.push('/login')
  //       return;
  //     }
  //   } else {
  //     if (VueCookies.get('authToken')) {
  //       router.push('/home')
  //       return;
  //     }
  //   }
  // }

  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
